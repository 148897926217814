.about--container, .interests--container {
    padding: 0 12% 10% 12%;
    width: 100%;
}

.about--container h2, .interests--container h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4em;
    text-align: left;
    color: #FFFFFF;
    margin: 0 0 0 0;
}

.about--container p, .interests--container p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 0.9em;
    line-height: 1.5em;
    text-align: left;
    color: #DCDCDC;
    margin: 2% 0 0 0;
}