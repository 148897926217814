.footer--container {
    padding: 6% 0 6% 0;
    width: 100%;
    background: #161619;
    border-radius: 0 0 10px 10px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;;
}

.footer--container a {
    color: #918E9B;
    margin: 0 5% 0 5%;
}

.footer--container i {
    font-size: 2.5em;
}