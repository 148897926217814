* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    background: #23252C;
    height: 100%;
}

.app--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25em;
    margin: auto;
    background: #1A1B21;
    border-radius: 10px;
    height: fit-content;
}