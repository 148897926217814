.avatar {
    width: 100%;
    border-radius: 10px 10px 0 0;
}

.info--container {
    padding: 0 0 10% 0;
    width: 100%;
}

.info--container h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    text-align: center;
    color: #FFFFFF;
    margin: 5% 0 0 0;
}

.info--container h3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1em;
    text-align: center;
    color: #F3BF99;
    margin: 1% 0 0 0;
}

.info--website {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1em;

    text-align: center;
    color: #FFFFFF;
    margin: 3% 0 6% 0;
}

.info--website a {
    color: #FFFFFF;
    text-decoration: none;
}

.info--buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
}

.info--buttons > a {
    width: 35%;
    border-radius: 6px;
    padding: 2%;
    align-items: center;
    margin: 0 3% 0 3%;

    display: flex;
    flex-direction: row;
    justify-content: center;

    text-decoration: none;
}

.info--buttons > a > p, i {
    margin: 0;
    margin: 0 4% 0 4%;
    font-size: 1.2em;
}

#email--button {
    background-color: #FFFFFF;
}

#email--button > * {
    color: #374151;
}

#linkedin--button {
    background-color: #5093E2;
}

#linkedin--button > * {
    color: #FFFFFF;
}